import React from 'react';

import { connect } from 'react-redux';

import {
	USER_PERMISSION__IS_EXPORT_ALLOWED,
	USER_PERMISSION__USE_ANALYSIS,
	USER_PERMISSION__VIEW_REPORTS,
} from '../../../../constants/userPermissionsConstants';

import imageUtils from '../../../../appUtils/imageUtils';
import aclService from '../../../../services/acl';
import { isSequentialModeEnabledForVisit } from '../../utils';

import editorActions from '../../../../actions/editorActions';
import { analyzeImage } from '../../actions';

import editorSelectors from '../../../../selectors/editorSelectors';
import collectionsSelectors from '../../../../selectors/collectionsSelectors';
import imagesSelectors from '../../../../selectors/imagesSelectors';
import analyseSelectors from '../../../../selectors/analyseSelectors';
import userSelectors from '../../../../selectors/userSelectors';

import ResolverControllerButtons from './ResolverControllerButtons';
import pathUtils from "../../../../appUtils/pathUtils";


export default connect(
	(state, props) => {
		const findingsFilter = props.findingsFilter;
		const editorData = editorSelectors.selectEditor(state);
		const currentCollectionId = editorData.currentCollectionHashName;
		const collection = collectionsSelectors.selectCollectionById(state, {
			id: currentCollectionId,
		});
		const currentImageId = editorData.currentImageId;
		const currentImage = imagesSelectors.selectImageById(state, {
			id: currentImageId,
		});
		const user = userSelectors.selectUserData(state);
		const isSequentialModeEnabled = user.is_sequential_mode === true && isSequentialModeEnabledForVisit({ currentImage }) === false;
		const isCollectionRotated = editorData.notAnalyzedImages.some((imageId) => {
			const image = imagesSelectors.selectImageById(state, {
				id: imageId,
			});

			return (image.examination === editorData.currentExamination);
		});
		const reAnalyzeRequired = collection.images
			.filter((image) => image.examination === editorData.currentExamination)
			.some((image) => image.reanalyze_required === true);
		const isAutoChartShortFilter = editorData.isAutoChartShortFilter;
		const isPatientTooYoung = editorData.currentExamination
			? true === collection.images
				.filter((image) => image.examination === editorData.currentExamination)
				.some((image) => imageUtils.isImagePatientOlder(image) === false)
			: imageUtils.isImagePatientOlder(currentImage) === false;

		return {
			shouldDisplayAnalyzeButton: (
				userSelectors.selectUserData(state).username === collection.username &&
				aclService.checkPermission(USER_PERMISSION__USE_ANALYSIS) &&
				(
					isCollectionRotated === true ||
					reAnalyzeRequired === true
				)
			),
			shouldDisplayReportButton: aclService.checkPermission(USER_PERMISSION__VIEW_REPORTS) === true,
			shouldDisplayExportButton: (
				aclService.checkPermission(USER_PERMISSION__IS_EXPORT_ALLOWED) === true &&
				(user.is_patient_age_restricted !== true || isPatientTooYoung === false)
			),
			isExportButtonDisabled: editorData.teethConflicts.length > 0,
			isImageInAnalysis: analyseSelectors.selectIsImageInAnalysis(state, { imageId: currentImageId }),
			isImageAnalyzed: analyseSelectors.selectIsImageAnalyzed(state, { imageId: currentImageId }),
			reportImageUrl: pathUtils.getExternalLink(`/collections/${currentCollectionId}/image/${currentImage.hashname}/treatment_plan/report`),
			isSequentialModeEnabled,
			shouldHighlightAnalyzeButton: (
				editorData.notAnalyzedImages.length > 0 ||
				reAnalyzeRequired === true
			),
			isPatientTooYoung,
			canReanalyze: (
				editorData.notAnalyzedImages.length > 0 ||
				reAnalyzeRequired === true ||
				currentImage.is_analyzed_once === true
			),
			onGetExportLink: (params) => imageUtils.getExportLink({ findingsFilter, editorData, currentImage, currentCollectionId, isAutoChartShortFilter, ...params }),
		};
	},
	(dispatch) => ({
		onAnalyzeImage: () => dispatch(analyzeImage()),
		onResetMode: () => dispatch(editorActions.resetMode()),
	})
)(ResolverControllerButtons);
